<template>
  <v-layout full-width fill-height>
    <template>
      <v-layout full-width>
        <v-container class="mt-3 ml-0" style="max-width: 900px">
          <v-form
            class="compose-form"
            @submit.prevent="submit"
            lazy-validation
            ref="composeMail"
          >
            <v-text-field
              v-show="errorMessage.length > 0"
              color="red"
              class="compose-form--input input-error px-3"
              solo
              flat
              label="error"
              v-model="errorMessage"
              hide-details="true"
              readonly
            ></v-text-field>

            <v-combobox
              v-model="receiver"
              label="To"
              class="compose-form--input px-3"
              multiple
              flat
              solo
              hide-details
              :rules="[
                (v) =>
                  /^(?:\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3}))(,(?:\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})))*$/.test(
                    v
                  ) || 'Invalid Email(s)',
              ]"
              chips
            >
              <template v-slot:selection="data">
                <v-chip
                  :key="JSON.stringify(data.item)"
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  :disabled="data.disabled"
                  @click:close="data.parent.selectItem(data.item)"
                >
                  <v-avatar
                    class="accent white--text"
                    left
                    v-text="data.item.slice(0, 1).toUpperCase()"
                  ></v-avatar>
                  {{ data.item }}
                </v-chip>
              </template>
            </v-combobox>

            <v-text-field
              v-show="showCC"
              class="compose-form--input px-3"
              solo
              flat
              label="CC"
              v-model="CC"
              hide-details="true"
            ></v-text-field>
            <v-text-field
              class="compose-form--input px-3"
              solo
              flat
              label="Add a subject"
              v-model="subject"
              hide-details="true"
            ></v-text-field>
            <v-textarea
              class="compose-form--input px-3"
              solo
              flat
              hide-details="true"
              v-model="body"
            ></v-textarea>

            <v-container>
              <template v-for="(file, i) in attached">
                <v-chip
                  class="ma-2"
                  @click:close="removeAttached(i)"
                  :key="i"
                  color="blue-grey darken-1"
                  outlined
                  close
                  >{{ file.name }}
                </v-chip>
              </template>
            </v-container>

            <v-layout
              align-center
              justify-space-between
              class="compose-form--footer px-3 py-1"
            >
              <v-btn
                :disabled="submitDisable"
                color="primary"
                class="white--text mr-4"
                type="submit"
              >
                submit
              </v-btn>
              <v-file-input
                hide-input
                multiple
                class="pa-0 ma-0"
                show-size
                truncate-length="42"
                v-model="inputAttachments"
              ></v-file-input>
              <v-btn @click="clear"> Discard </v-btn>
            </v-layout>
          </v-form>
        </v-container>
      </v-layout>
    </template>
  </v-layout>
</template>

<script>
import buffer from "@/plugins/file-to-buffer";
import bufferToBase64 from "@/plugins/buffer-to-base64";

export default {
  created() {
    this.randamString = this.generateRandomWords;
  },
  data() {
    return {
      showCC: false,
      randamString: "",
      errorMessage: "",
      submitDisable: false,
      file_size: 0,

      sender: "",
      receiver: [],
      subject: "",
      body: "",
      attached: [],
      params: {},

      CC: [],
      selected: [],
      inputAttachments: [],
    };
  },
  watch: {
    inputAttachments(newFiles) {
      this.addAttachments(newFiles);
    },
  },
  computed: {
    generateRandomWords() {
      return Math.random().toString(36).substring(7);
    },
    extendedPublicKey() {
      return this.$store.getters.extendedPublicKey;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  methods: {
    async submit() {
      if (!this.$refs.composeMail.validate())
        this.$store.dispatch("ALERT_WARNING", "One or more field are invalid!");

      if (this.receiver.length == 0)
        this.errorMessage = "This message must have at least one recipient.";
      else if (
        !/^(?:\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3}))(,(?:\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})))*$/.test(
          this.receiver.toString()
        )
      )
        this.errorMessage = "Reciver email is invalid.";
      // else if (this.receiver.includes(this.userInfo.emailId))
      //   this.errorMessage = "Sending mail to yourself is prohibited";
      else if (
        this.showCC &&
        !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.CC)
      )
        this.errorMessage = "CC email is invalid.";
      else {
        this.submitDisable = true;
        this.errorMessage = "";

        const emailIds = this.receiver.concat(
          [...this.CC, this.userInfo.emailId].filter(
            (item) => this.receiver.indexOf(item) < 0
          )
        );

        this.params = {
          sender: this.userInfo.emailId,
          receiver: this.receiver,
          subject: this.subject,
          attachments: this.attached,
          CC: this.CC,
          secrets: {},
        };
        this.params.body = await this.$store.dispatch("DATA_ENCRYPT", {
          message: this.body,
          secret: this.randamString,
        });
        const vueObj = this;

        this.$store
          .dispatch("GET_PUBIC_KEYS", emailIds)
          .then(({ data }) => {
            vueObj.sendMail(
              this.params,
              data.emailToPubKeyMap,
              vueObj.randamString
            );
          })
          .catch(() => {
            this.$store.dispatch(
              "ALERT_ERROR",
              "Please check you connection and try again later!"
            );
          });
      }
    },
    async sendMail(params, emailToPubKeyMap, randomString) {
      this.$store.dispatch("SNACKBAR", `Sending Secured Mail`);
      let secrets = {};
      for (const key in emailToPubKeyMap) {
        secrets[key] = await this.$ledgermail_v2.encrypt(
          randomString,
          emailToPubKeyMap[key]
        );
      }
      params.secrets = JSON.stringify(secrets);
      const vueObj = this;

      this.$store
        .dispatch("COMPOSE_MAIL", params)
        .then(() => {
          vueObj.$store
            .dispatch("MAIL_INIT", { emailId: vueObj.userInfo.emailId })
            .then(() => {
              vueObj.$store.dispatch("SNACKBAR", `Mail Sent`);
              vueObj.$router.go(-1);
            });
        })
        .catch((error) => {
          this.submitDisable = false;
          this.$store.dispatch("ALERT_ERROR", error);
        });
    },
    async addAttachments(files) {
      for (let file of files) {
        this.file_size += file.size;

        if (this.file_size > 5000000) {
          this.$store.dispatch(
            "SNACKBAR",
            `Attachments exceeds the size limit of 5MB`
          );
          break;
        }

        this.attached.push({
          name: file.name,
          type: file.type,
          size: file.size.toString(),
        });
        this.fileToString(file, this.attached.length - 1).then((data) => {
          this.attached[data.index].data = data.base64buf;
        });
      }
    },
    async fileToString(fileToUpload, index) {
      return new Promise((reslove) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(fileToUpload);
        reader.onloadend = async () => {
          const fileType = fileToUpload.type;
          const prefix = `data:${fileType};base64,`;
          const buf = buffer.Buffer(reader.result);

          const base64buf = await this.$store.dispatch("DATA_ENCRYPT", {
            message: (prefix + bufferToBase64(buf)).toString(),
            secret: this.randamString,
          });
          reslove({ base64buf, index });
        };
      });
    },
    removeAttached(i) {
      const file = this.attached.splice(i, 1);
      this.file_size -= parseInt(file.size);
    },
    clear() {
      this.sender = "";
      this.receiver = "";
      this.subject = "";
      this.body = "";
      this.attachments = [];
      this.CC = [];
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.compose-form {
  border: 1px solid #ccc;
  border-radius: 2px;
  &--input {
    border-bottom: 1px solid #ccc !important;
  }
  &--footer {
    background: #f3f3f3;
  }
  .input-error {
    font-size: 0.75rem;
    color: red !important;
    border-left: 4px solid red;
    border-radius: 0;
  }
}
</style>